<template>
  <div>
    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Plano</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <card>
        <h3 slot="header" class="mb-0">Plano {{ plan.typeSub }}</h3>
        <div class="form-row">
          <div class="col-md-12">
            <base-input label="Nome" name="nome" placeholder="Nome" required v-model="plan.name">
            </base-input>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4">
            <label class="form-control-label"> Taxa de Adesão </label>
            <vue-numeric name="taxaadesao" placeholder="Taxa de Adesão" class="form-control" v-model="plan.accessionFee"
              separator="." v-bind:precision="2" v-bind:minus="false" />
          </div>
          <div class="col-md-4">
            <label class="form-control-label"> Valor Titular </label>
            <vue-numeric name="precotitular" placeholder="Valor Titular" class="form-control" v-model="plan.priceHolder"
              separator="." v-bind:precision="2" v-bind:minus="false" />
          </div>
          <div class="col-md-4">
            <label class="form-control-label"> Valor Dependente </label>
            <vue-numeric name="precodependente" placeholder="Valor Dependente" class="form-control"
              v-model="plan.priceDependent" separator="." v-bind:precision="2" v-bind:minus="false" />
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="col-md-4">
            <base-input label="Ativo" required name="ativo">
              <el-select v-model="plan.isActive" placeholder="Ativo?">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-md-2">
            <base-input label="Renovação automática" required name="automaticRenovation">
              <el-select v-model="plan.automaticRenovation" placeholder="Renovação automática?">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-md-2">
            <base-input label="Dias para Bloqueio" name="bloqueio" id="bloqueio" required
              placeholder="Dias para Bloqueio" v-model="plan.daysToBlock" v-mask="['##']"></base-input>
          </div>
          <div class="col-md-2">
            <base-input label="Retentar pagamento" required name="retry">
              <el-select v-model="plan.retry" placeholder="Retentar" @change="habilita()">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-md-2">
            <base-input :disabled="!plan.retry" label="Qtde tentativas" name="numero" id="numero" required
              placeholder="Qtde tentativas" v-model="plan.attempts" v-mask="['##']"></base-input>
          </div>
        </div>

        <div class="form-row mt-3">
          <div class="col-md-4">
            <base-input label="Disponível no Site" required name="avaliableOnline">
              <el-select v-model="plan.avaliableOnline" placeholder="Disponível no Site?">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-md-2">
            <base-input label="Possui Fidelidade" required name="hasfidelity">
              <el-select v-model="plan.hasfidelity" placeholder="Possui Fidelidade?">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-md-2">
            <base-input label="Permite Dependentes" required name="hasdependent">
              <el-select v-model="plan.hasdependent" placeholder="Permite Dependentes?">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-md-2">
            <base-input label="Qnt limitada de vida" required name="hasLimitDependent">
              <el-select v-model="plan.hasLimitDependent" @change="habilitaQtdeVida()"
                placeholder="Possui quantidade limitade de vida?">
                <el-option v-for="option in opcoes" :key="option.label" :label="option.label" :value="option.value">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-md-2">
            <label class="form-control-label"> Quantidade de vidas </label>
            <vue-numeric maxlength="6" :disabled="!plan.hasLimitDependent" required name="limitDependent" placeholder=""
              class="form-control" v-model="plan.limitDependent" separator="." v-bind:minus="false" />
          </div>
          <div class="col-md-2">
            <label class="form-control-label">Tamanho da fidelidade</label>
            <vue-numeric maxlength="6" :disabled="!plan.hasfidelity" name="fidelitySize" placeholder=""
              class="form-control" v-model="plan.fidelitySize" separator="." v-bind:minus="false" />
          </div>
          <div class="col-md-3">
            <label class="form-control-label">Tamanho da competência</label>
            <div class="input-group mb-3">
              <vue-numeric name="competence" class="form-control" v-model="plan.competence" v-bind:precision="0"
                v-bind:minus="false" />
              <div class="input-group-prepend">
                <select class="form-control mb-2 ml-2" v-model="plan.competenceType">
                  <option value="MONTH">Mês</option>
                  <option value="YEAR">Ano</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-md-2">
            <label class="form-control-label"> Parcelamento mínimo </label>
            <vue-numeric  
              name="minInstallments" 
              class="form-control" 
              v-model="plan.minInstallments"
              :min="1" 
              :empty-value="1" 
              required />
          </div>

          <div class="col-md-2">
            <label class="form-control-label"> Parcelamento máximo </label>
            <vue-numeric 
              name="maxInstallments" 
              class="form-control"
              v-model="plan.maxInstallments"
              :min="plan.minInstallments" 
              :empty-value="12"
            required />
          </div>
        </div>

        <div class="col-md-12">
          <h4>Formas de pagamento disponíveis</h4>
          <div class="form-check" v-for="type in paymentTypes" :key="type.value">
            <input class="form-check-input" type="checkbox" :value="type.value" id="paymenttype" v-model="payments" />
            <label class="form-check-label" for="paymenttype">
              {{ type.label }}
            </label>
          </div>
        </div>
        <hr />

        <fieldset>
          <legend>Campos de personalização da página de Checkout</legend>
          <div class="form-row">
            <div class="col-md-12">
              <base-input label="Nome site" name="nomesite" placeholder="Nome site" v-model="plan.siteName">
              </base-input>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <base-input label="Descrição" name="descricao" placeholder="Descrição" v-model="plan.description">
              </base-input>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="col-md-4">
              <label class="form-control-label">Taxa de Adesão de</label>
              <vue-numeric name="taxaadesaode" placeholder="Taxa de Adesão de" class="form-control"
                v-model="plan.priceAccessionFeeFrom" separator="." v-bind:precision="2" v-bind:minus="false" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">Valor Titular de</label>
              <vue-numeric name="precotitularde" placeholder="Valor Titular de" class="form-control"
                v-model="plan.priceHolderFrom" separator="." v-bind:precision="2" v-bind:minus="false" />
            </div>
            <div class="col-md-4">
              <label class="form-control-label">Valor Dependente de</label>
              <vue-numeric name="precodependentede" placeholder="Valor Dependente de" class="form-control"
                v-model="plan.priceDependentFrom" separator="." v-bind:precision="2" v-bind:minus="false" />
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="col-md-4">
              <label class="form-control-label">Ordem no site</label>
              <vue-numeric name="siteorder" placeholder="Ordem no site" class="form-control" v-model="plan.siteOrder"
                v-bind:precision="0" v-bind:minus="false" />
            </div>
            <div class="col-md-6">
              <label class="form-control-label"></label>
              <div class="form-check mb-2 mt-3">
                <input class="form-check-input" type="checkbox" id="preferred" v-model="plan.preferred">
                <label class="form-check-label" for="preferred">
                  Plano mais escolhido
                </label>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <label class="form-control-label">Denefícios</label>
              <quill-editor v-model="plan.benefits" />
            </div>
          </div>
        </fieldset>
      </card>

      <div class="col-md-12 text-center mb-3">
        <base-button type="primary" @click="save">Salvar</base-button>
      </div>
    </div>
    <!--- fecha conteudo -->
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn, Tooltip } from "element-ui";
import axios from "axios";
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    typeSub: {
      type: String,
      default: "PF",
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [quillEditor.name]: quillEditor
  },
  mounted() {
    this.loadPlan();
  },
  data() {
    return {
      opcoes: [
        { label: "Sim", value: true },
        { label: "Não", value: false },
      ],
      plan: {
        name: null,
        attempts: null,
        priceHolder: 0,
        priceDependent: 0,
        accessionFee: 0,
        daysToBlock: 30,
        isActive: true,
        avaliableOnline: false,
        hasfidelity: false,
        automaticRenovation: false,
        retry: true,
        hasdependent: true,
        limitDependent: 0,
        hasLimitDependent: false,
        typeSub: null,
        priceAccessionFeeFrom: null,
        priceDependentFrom: null,
        priceHolderFrom: null,
        siteName: "",
        siteOrder: 0,
        competence: 1,
        competenceType: "MONTH",
        benefits: "",
        preferred: false,
        description: "",
        fidelitySize: null,
        minInstallments: 1,
        maxInstallments: 12
      },
      payments: [],
      paymentTypes: [
        { label: "Cartão de Crédito", value: "CREDIT_CARD" },
        { label: "Cartão de Débito", value: "DEBIT_CARD" },
        { label: "Boleto Bancário", value: "TICKET" },
        { label: "Carnê", value: "TICKETS" },
        { label: "Débito em conta", value: "DEBIT_ACCOUNT" },
        // {label: 'Cartão de Crédito (Máquina)', value: 'CREDIT_CARD_LOCAL'},
        // {label: 'Cartão de Débito (Máquina)', value: 'DEBIT_CARD_LOCAL'},
        // {label: 'Dinheiro', value: 'MONEY'}
      ],
    };
  },
  methods: {
    habilita() {
      if (!this.plan.retry) {
        this.plan.attempts = null;
      }
    },
    loadPlan() {
      if (this.id != 0) {
        //load plan data
        this.$clubApi
          .get("/plan/" + this.id)
          .then((response) => {
            this.plan = response.data.object;
          })
          .catch((error) => {
            this.$notify({ type: "warning", message: error.response.data.msg });
          })
          .finally(() => {
            NProgress.done();
          });

        //load plans payments type
        this.$clubApi
          .get("/plan/" + this.id + "/list/payments")
          .then((response) => {
            this.payments = response.data.object;
            console.log(this.payments);
          })
          .catch((error) => {
            this.$notify({ type: "warning", message: error.response.data.msg });
          })
          .finally(() => {
            NProgress.done();
          });
      } else {
        this.plan.typeSub = this.typeSub;
      }
    },
    save() {
      if (
        this.plan.retry &&
        this.plan.attempts != "" &&
        this.plan.attempts == 0
      ) {
        this.$notify({
          type: "warning",
          message: "Quantidade de tentativas tem que ser maior que zero.",
        });
        return;
      }

      if (this.plan.daysToBlock == "" || this.plan.daysToBlock < 0) {
        this.$notify({
          type: "warning",
          message: "Dias para bloqueio deve ser maior que zero.",
        });
        return;
      }

      if (this.plan.hasLimitDependent) {
        if (this.plan.limitDependent === 0) {
          this.$notify({
            type: "warning",
            message: "Quantidade de vidas tem que ser maior que zero.",
          });
          return;
        }
      }

      if (this.id == null) {
        return false;
      }
      this.$clubApi
        .post("/plan/save", this.plan)
        .then(() => {
          this.$notify({ type: "success", message: "Plano salvo com sucesso" });
        })
        .catch((error) => {
          this.$notify({ type: "warning", message: error.response.data.msg });
        })
        .finally(() => {
          NProgress.done();

          //salvando tipos de pagamento
          this.$clubApi
            .post("/plan/save/paymenttype/" + this.id, this.payments)
            .then(() => { })
            .catch((error) => {
              this.$notify({
                type: "warning",
                message: error.response.data.msg,
              });
            })
            .finally(() => {
              NProgress.done();
            });
        });

      axios.post(
        "https://bdaf81ikua.execute-api.us-east-1.amazonaws.com/log",
        JSON.stringify({
          user: localStorage.getItem("name"),
          request: this.plan
        }),
        {
          headers: {
            "Content-Type": "application/json"
          }
        });
    },
    habilitaQtdeVida() {
      if (!this.plan.hasLimitDependent) {
        this.plan.limitDependent = 0;
      }
    },
  },
};
</script>
<style></style>
